import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import Layout from '../components/layout'
import SiteMetadata from '../components/site-metadata'
import { Header } from 'semantic-ui-react'
import ArticleList from '../components/article-list'
import PropTypes from 'prop-types'

class TagsIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const postsByTag = {}
    posts.forEach(post => {
      post.node.tags.forEach(tag => {
        if (!postsByTag[tag]) postsByTag[tag] = []
        postsByTag[tag].push(post)
      })
    })
    let postData = Object.keys(postsByTag).map(k => {
      return {
        tag: k,
        posts: postsByTag[k],
      }
    })
    // 多い順にしたいから `-`をつける
    postData = sortBy(postData, [o => -o.posts.length])
    return (
      <Layout>
        <SiteMetadata
          pathname={this.props.location.pathname}
          contentTitle={'タグ一覧'}
          isIndexPage
        />
        <div className="wrapper">{postData.map(this._renderByTag)}</div>
      </Layout>
    )
  }

  _renderByTag(data, idx) {
    return (
      <div key={data.tag} style={idx !== 0 ? { marginTop: '3em' } : {}}>
        <Header as={'h2'} dividing>
          {data.tag}
        </Header>
        <ArticleList posts={data.posts} />
      </div>
    )
  }
}

TagsIndex.propTypes = {
  location: PropTypes.any,
}

export default TagsIndex

export const pageQuery = graphql`
  query TagsIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
